import BasePage from '@/found/components/table_page';

export default {
  name: 'detail_children_list',
  extends: BasePage,
  props: {
    formConfig: Object,
  },
  data() {
    return {
      // requestType: 'GET',
      params: {
        reportType: 2,
      },
    };
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
  },
  created() {
    this.params.terminalCodeComplete = this.formConfig.row.terminalCode;
    this.getConfigList('detail_children_list');
  },
};
